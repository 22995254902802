import React, { useEffect, useRef, useState } from 'react';
import { Colors } from '../../util/Colors';
import {
  getDateString,
  getRelativeDateStr,
  getTimeString,
} from '../../util/DateTimeUtils';

import { useParams } from 'react-router-dom';
import { authContext } from '../../contexts/AuthContext';
import {
  DateInput,
  LocationInput,
  TimeInput,
} from '../../components/InviteFormComponents';

import { produce } from 'immer';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { WS_URL } from '../../util/Constants';
import { useNetworkManager } from '../../util/Network';
import { EditInviteForm } from '../../components/EditInviteForm';
import { DestructiveActionConfirmation } from '../../components/DestructiveActionConfirmation';
import {
  InvitesContext,
  isInviteCancelled,
} from '../../contexts/InvitesContext';
import { ConversationHeader } from './ConversationHeader';
import { v4 as uuidv4 } from 'uuid';
import {
  subscribeToNativeChannel,
  unsubscribeFromNativeChannel,
} from '../../util/NativeBridge';
import { PaperAirplaneIcon } from '@heroicons/react/20/solid';
import { DropDownMenu } from '../../components/IconDropDownMenu';
import { BasicModal } from '../../components/BasicModal';

const socket_id = uuidv4();

const getMessagesBottom = () => {
  const footer = document.getElementById('fixed-message-bottom-popover');

  // now get the top of the footer
  return (window.innerHeight || 0) - (footer?.offsetTop || 0);
};

const isValidSuggestion = (suggestion) => {
  return (
    suggestion &&
    Object.values(suggestion || {})?.[0] &&
    Object.values(suggestion || {})?.[0] !== 'invalid'
  );
};

const ConversationScreen = () => {
  const { inviteId } = useParams();
  const [invite, setInvite] = useState(); // TODO: move this to invite store
  const [messages, setMessages] = useState([]);
  const { user } = React.useContext(authContext);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [confirmDeleteAlertOpen, setConfirmDeleteAlertOpen] = useState(false);
  const [confirmDeclineAlertOpen, setConfirmDeclineAlertOpen] = useState(false);

  const { makeGetRequest } = useNetworkManager();

  // Websocket stuff
  useEffect(() => {
    let socket = undefined;

    if (messages) {
      // Create a WebSocket connection
      socket = new ReconnectingWebSocket(WS_URL);

      // Add event listeners
      socket.addEventListener('open', () => {
        console.log('WebSocket connected');
        socket.send(
          JSON.stringify({
            event: 'view_conversation',
            socket_id,
            invite_id: inviteId,
          })
        );
      });

      socket.addEventListener('message', (event) => {
        const message = JSON.parse(event.data);

        if (message.type === 'message') {
          setMessages((prevMessages) => {
            return [...prevMessages, message.createdMessage];
          });
        } else if (message.type === 'invite_accepted') {
          setMessages((prevMessages) => {
            return [...prevMessages, message.createdMessage];
          });
          const userId = message.createdMessage.author.user_id;
          // get the particpant from the invite and update their status
          setInvite((prevInvite) => {
            return produce(prevInvite, (draft) => {
              const participant = draft.participants.find(
                (p) => p.user_id === userId
              );
              participant.status = 'confirmed';
            });
          });
        } else if (message.type === 'invite_declined') {
          setMessages((prevMessages) => {
            return [...prevMessages, message.createdMessage];
          });
          const userId = message.createdMessage.author.user_id;
          // get the particpant from the invite and update their status
          setInvite((prevInvite) => {
            return produce(prevInvite, (draft) => {
              const participant = draft.participants.find(
                (p) => p.user_id === userId
              );
              participant.status = 'declined';
            });
          });
        } else if (message.type === 'invite_changed') {
          setInvite((prevInvite) => {
            return { ...prevInvite, ...message.updatedInvite };
          });
        } else if (message.type === 'suggestion_changed') {
          setMessages((prevMessages) => {
            return prevMessages.map((prevMessage) => {
              if (
                prevMessage?.suggestion?.suggestion_id ===
                message.updatedSuggestion.suggestion_id
              ) {
                return {
                  ...prevMessage,
                  suggestion: message.updatedSuggestion,
                };
              } else {
                return prevMessage;
              }
            });
          });
        } else if (message.type === 'invite_cancelled') {
          setInvite((prevInvite) => {
            return { ...prevInvite, ...message.updatedInvite };
          });
          setMessages((prevMessages) => {
            return [...prevMessages, message.createdMessage];
          });
        }
      });

      socket.addEventListener('close', () => {
        console.log('WebSocket closed');
      });
    }

    return () => {
      // Clean up: close WebSocket connection
      socket && socket.close();
    };
  }, []);

  useEffect(() => {
    const subscriptionToken = subscribeToNativeChannel(
      'lifecycle',
      ({ type }) => {
        if (type === 'returningToForeground') {
          // Check for new messages when we come back from the background
          makeGetRequest(`invites/${inviteId}/messages`).then(
            ({ response, error }) => {
              if (response.length !== messages.length) {
                setMessages(response);
              } else {
                console.error(error);
              }
            }
          );
        }
      }
    );

    return () => {
      unsubscribeFromNativeChannel('lifecycle', subscriptionToken);
    };
  }, []);

  useEffect(() => {
    const fetchInviteAndMessages = async () => {
      // Fetch invite
      const { response, error } = await makeGetRequest(`invites/${inviteId}`);

      if (response) {
        // reorder the participants field so that the author is first
        const author = response.participants.find(
          (p) => p.user_id === response.author_id
        );

        const participants = response.participants.filter(
          (p) => p.user_id !== response.author_id
        );

        // reorder the participants so that now the current user is 2nd
        const currentUser = participants.find(
          (p) => p.user_id === user.user_id
        );
        if (currentUser) {
          currentUser.name = 'Me';

          participants.splice(participants.indexOf(currentUser), 1);
          participants.unshift(currentUser);
        }

        response.participants = [author, ...participants];

        setInvite(response);
      } else {
        console.error('Error fetching invite:', error);
      }

      // Fetch messages
      const { response: messageResponse, error: messageError } =
        await makeGetRequest(`invites/${inviteId}/messages`);

      if (messageResponse) {
        setMessages(messageResponse);
      } else {
        console.error(messageError);
      }
    };

    fetchInviteAndMessages();
  }, []);

  const bottomRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [messages]);

  // use invites context
  const { editExistingInvite, cancelInvite, declineInvite } =
    React.useContext(InvitesContext);

  const firstMessageString = (function () {
    if (!invite) {
      return '';
    }
    if (invite.author_id === user.user_id) {
      return 'I started the chat';
    } else {
      const user = invite.participants.find(
        (p) => p.user_id === invite.author_id
      );

      return `${user.name} started the chat`;
    }
  })();

  const messagesBottom = getMessagesBottom();
  const marginBottom = messagesBottom > 0 ? messagesBottom + 5 : 0;
  return invite ? (
    <div style={{ marginBottom }} className=''>
      <DestructiveActionConfirmation
        open={confirmDeclineAlertOpen}
        setOpen={setConfirmDeclineAlertOpen}
        onConfirm={async () => {
          declineInvite({
            inviteId: invite.invite_id,
            userId: user.user_id,
          });
          setConfirmDeclineAlertOpen(false);
        }}
        title={"Are you sure you're out?"}
        confirmButtonText={"I'm sure"}
      />
      <DestructiveActionConfirmation
        open={confirmDeleteAlertOpen}
        setOpen={setConfirmDeleteAlertOpen}
        onConfirm={async () => {
          await cancelInvite(inviteId);
          setConfirmDeleteAlertOpen(false);
        }}
        title={'Are you sure you want to cancel this event?'}
        description={'This action cannot be undone'}
        confirmButtonText={"I'm sure"}
      />

      <BasicModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        title='Edit Invite'
      >
        <EditInviteForm
          invite={invite}
          onComplete={async (originalInvite) => {
            console.log('original Invite', originalInvite);
            const { response, error } =
              await editExistingInvite(originalInvite);
            if (response) {
              // Merge the new invite with the original invite
              setInvite({ ...invite, ...response });
            } else {
              // TODO: error handling
            }
            setEditModalOpen(false);
          }}
        />
      </BasicModal>

      <ConversationHeader
        invite={invite}
        setEditModalOpen={setEditModalOpen}
        setConfirmDeleteAlertOpen={setConfirmDeleteAlertOpen}
        setConfirmDeclineAlertOpen={setConfirmDeclineAlertOpen}
      />

      <div className=' space-y-5 px-5 text-center'>
        <div>
          <div className='text-sm text-gray-700'>
            {getRelativeDateStr(new Date(invite.start_date))}
          </div>

          {firstMessageString}
        </div>
        {messages.map((message, i) => {
          let dateString;
          const needsDate =
            i === 0 ||
            new Date(message.created_at) -
              new Date(messages[i - 1].created_at) >
              1000 * 60 * 60 * 2;
          if (needsDate) {
            const messageDate = new Date(message.created_at);
            dateString = getRelativeDateStr(messageDate);
          }
          const suggestion = message.suggestion;

          return (
            <div className='' key={message.message_id}>
              {dateString && (
                <div className='text-sm text-gray-700'>{dateString}</div>
              )}

              <Message message={message} />
              {suggestion && (
                <div style={{ marginBottom: 15 }}>
                  <Suggestion
                    invite={invite}
                    message={message}
                    author={message.author.name}
                    suggestion={suggestion}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div
        id='fixed-message-bottom-popover'
        className='fixed bottom-0 w-full flex flex-col items-center bg-zebraTheme-light py-2 '
      >
        <ResponseBottomPopover invite={invite} />
      </div>
    </div>
  ) : (
    <></>
  );
};

const getEditString = (message) => {
  let messageToReturn = message.content;
  if (
    message.content.includes('start time') ||
    message.content.includes('end time')
  ) {
    const messageParts = message.content.split('::::');
    const timeString = getTimeString(messageParts[1]);
    return messageParts[0] + timeString;
  } else if (message.content.includes('start date')) {
    const messageParts = message.content.split('::::');
    const dateString = getDateString(messageParts[1]);
    return messageParts[0] + dateString;
  } else {
    return messageToReturn.replace('::::', '').replace('null', 'TBD');
  }
};
const Message = ({ message }) => {
  const { user } = React.useContext(authContext);
  const isAuthor = message.author.user_id === user.user_id;

  const textAlign = isAuthor ? 'text-right' : 'text-left';
  switch (message.type) {
    case 'ACCEPT':
      return (
        <p className='italic font-sm'>
          {isAuthor ? 'I' : message.author.name} accepted the invite
        </p>
      );
    case 'DECLINE':
      return (
        <p className='italic font-sm'>
          {isAuthor ? 'I' : message.author.name} declined the invite
        </p>
      );
    case 'EDIT':
      return (
        <p className='italic font-sm'>
          {isAuthor ? 'I' : message.author.name} {getEditString(message)}
        </p>
      );
    case 'COMMENT':
    case 'SUGGEST': {
      if (!message.content) {
        return <></>;
      }
      const messageDate = new Date(message.created_at);
      let timeString;
      if (messageDate < new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)) {
        timeString = messageDate.toLocaleTimeString('en-us', {
          hour: 'numeric',
          minute: '2-digit',
        });
      } else {
        timeString = messageDate.toLocaleTimeString('en-us', {
          hour: 'numeric',
          minute: '2-digit',
        });
      }

      return (
        <div
          className={
            'flex flex-col ' +
            (isAuthor && ' items-end justify-center ') +
            ' ' +
            textAlign
          }
        >
          <>
            <div>
              <span className='font-semibold text-sm'>
                {isAuthor ? 'Me' : message.author.name}
              </span>{' '}
              <div className='ms-4 inline-block text-xs'>{timeString}</div>
            </div>

            <div
              className={
                'max-w-[70vw] w-fit bg-zebraTheme p-3 px-3 rounded-xl ' +
                textAlign
              }
            >
              {message.content}
            </div>
          </>
        </div>
      );
    }
    case 'EXIT': {
      let exitString = `${message.author.name} left`;
      if (message.comment) {
        exitString += `: ${message.content}`;
      }
      return <p className='italic text-sm'>{exitString}</p>;
    }
    case 'CANCEL': {
      return (
        <p className='italic text-xs'>
          {isAuthor ? 'I' : message.author.name} cancelled the event
        </p>
      );
    }
    default: {
      return (
        <>
          {message.author.name}: {message.content}
        </>
      );
    }
  }
};

const Suggestion = ({ invite, message, suggestion }) => {
  const { user } = React.useContext(authContext);
  const isAuthor = invite.author_id === user.user_id;

  let messageStr = `${
    message.author.user_id === user.user_id ? 'I' : message.author.name
  } suggested `;

  if (suggestion.location) {
    messageStr += `a new location: ${suggestion.location}`;
  } else if (suggestion.start_date) {
    messageStr += `a new date: ${getDateString(suggestion.start_date)}`;
  } else if (suggestion.start_time) {
    messageStr += `a new start time: ${getTimeString(suggestion.start_time)}`;
  } else if (suggestion.end_time) {
    messageStr += `a new end time: ${getTimeString(suggestion.end_time)}`;
  } else {
    messageStr = `Something went wrong with this suggestion`;
  }

  const isPending = suggestion.status === 'PENDING';
  return (
    <>
      <div className='flex flex-col items-center justify-center w-[90vw] m-auto text-sm gap-y-2'>
        {messageStr}

        <div className='flex flex-col text-right'>
          {isAuthor && isPending && (
            <div className='flex text-center'>
              <RespondButtons suggestion={suggestion} />
            </div>
          )}
          {suggestion.status === 'ACCEPTED' && (
            <p className='mt-2'>
              <span style={{ color: Colors.acceptedGreen, fontWeight: 'bold' }}>
                Accepted
              </span>{' '}
              by Organizer
            </p>
          )}
          {suggestion.status === 'REJECTED' && (
            <p>
              <span style={{ color: Colors.rejectedRed, fontWeight: 'bold' }}>
                Declined
              </span>{' '}
              by Organizer
            </p>
          )}
        </div>
      </div>
    </>
  );
};

function ResponseBottomPopover({ invite, onChange }) {
  const { user } = React.useContext(authContext);
  const [message, setMessage] = useState('');
  const [suggestion, setSuggestion] = useState(undefined);
  const [suggestionType, setSuggestionType] = useState('');

  const { makePostRequest } = useNetworkManager();

  const onSubmit = async (e) => {
    console.log({ suggestion });

    if (message || suggestion) {
      const requestBody = {
        input: {
          author_id: user.user_id,
          content: message || '',
          invite_id: invite.invite_id,
          suggestion: isValidSuggestion(suggestion) && suggestion,
        },
      };

      const { response, error } = await makePostRequest(
        'send_message',
        requestBody
      );

      if (response) {
        setMessage('');
        setSuggestion(undefined);
        setSuggestionType('');
      }
    }
  };
  const isSendButtonEnabled = message || isValidSuggestion(suggestion);

  return (
    <div className='w-[95%] flex flex-col items-center'>
      <div className='w-full relative'>
        <div
          role='button'
          className='absolute inset-y-0 right-4 pl-3 flex items-center'
        >
          <PaperAirplaneIcon
            onClick={onSubmit}
            className={`h-6 w-6 ${
              isSendButtonEnabled ? 'text-zebraTheme' : 'text-gray-400'
            }`}
          />
        </div>
        <textarea
          id='message-input-area'
          className='block p-2.5 py-4 w-full text-sm  rounded-xl placeholder-gray-400 resize-none  '
          rows={1}
          onInput={(e) => {
            const thisEl = e.target;
            if (thisEl.scrollHeight > thisEl.clientHeight) {
              if (thisEl.rows < 12) {
                thisEl.rows++;
              }
            } else if (thisEl.rows > 1) {
              thisEl.rows--;
            }
          }}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          placeholder='Message'
        ></textarea>
      </div>

      {invite.author_id !== user.user_id && (
        <>
          {!isInviteCancelled(invite) && (
            <SuggestionCreator
              suggestionType={suggestionType}
              setSuggestionType={setSuggestionType}
              suggestion={suggestion}
              setSuggestion={setSuggestion}
            />
          )}
        </>
      )}
    </div>
  );
}

const SUGGESTION_TYPES = {
  LOCATION: 'Location',
  START_TIME: 'Start Time',
  END_TIME: 'End Time',
  START_DATE: 'Date',
};

const SuggestionCreator = ({
  suggestionType,
  setSuggestionType,
  suggestion = {},
  setSuggestion,
}) => {
  const suggestionValues = Object.keys(SUGGESTION_TYPES).map((key) => {
    return {
      label: SUGGESTION_TYPES[key],
      onClick: () => {
        setSuggestionType(SUGGESTION_TYPES[key]);
      },
    };
  });

  return (
    <div className='flex flex-row space-evenly w-full mt-4'>
      <div
        className={
          'w-full flex justify-center items-center text-sm text-center '
        }
      >
        <DropDownMenu
          title={suggestionType || 'Suggest a change'}
          options={suggestionValues}
          anchor='bottom'
        />
      </div>

      <SuggestionFormComponent
        suggestion={suggestion}
        suggestionType={suggestionType}
        setSuggestion={setSuggestion}
      />
    </div>
  );
};

const SuggestionFormComponent = ({
  suggestion,
  suggestionType,
  setSuggestion,
}) => {
  switch (suggestionType) {
    case SUGGESTION_TYPES.START_TIME:
      return (
        <div className=' bg-white ml-3 rounded-xl outline-none border-none w-full'>
          <TimeInput
            onChange={(newVal) => setSuggestion({ start_time: newVal })}
            value={suggestion.start_time || ''}
          />
        </div>
      );

    case SUGGESTION_TYPES.END_TIME:
      return (
        <div className='bg-white ml-3 rounded-xl outline-none border-none w-full'>
          <TimeInput
            onChange={(newVal) => setSuggestion({ end_time: newVal })}
            value={suggestion.end_time || ''}
          />
        </div>
      );
    case SUGGESTION_TYPES.START_DATE:
      return (
        <div className=' bg-white ml-3 rounded-xl outline-none border-none w-full'>
          <DateInput
            onChange={(newVal) =>
              setSuggestion({ start_date: newVal.startDate })
            }
            value={suggestion.start_date || ''}
            popoverDirection='up'
          />
        </div>
      );
    case SUGGESTION_TYPES.LOCATION:
      return (
        <div className=' bg-white ml-3 rounded-xl outline-none border-none w-full'>
          <LocationInput
            onChange={(e) => setSuggestion({ location: e.target.value })}
            value={suggestion.location || ''}
          />
        </div>
      );
    default:
      return null;
  }
};

const RespondButtons = ({ suggestion }) => {
  const { makePostRequest } = useNetworkManager();

  const onPress = async (suggestion, status) => {
    const { response, error } = await makePostRequest('respond_to_suggestion', {
      suggestionId: suggestion.suggestion_id,
      status,
    });
  };

  return (
    <div className=' space-x-2'>
      <button
        onClick={(e) => {
          e.stopPropagation();
          onPress(suggestion, 'ACCEPTED');
        }}
        className='bg-lime-700 border-lime-700 border text-white rounded-md p-2 font-semibold'
      >
        Accept
      </button>

      <button
        onClick={(e) => {
          e.stopPropagation();
          onPress(suggestion, 'REJECTED');
        }}
        className='bg-red-700 border-red-700 border text-white rounded-md p-2 font-semibold'
      >
        Decline
      </button>
    </div>
  );
};

// ConversationScreen.whyDidYouRender = true;

export { ConversationScreen };
