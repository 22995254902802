import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { authContext } from '../contexts/AuthContext';
import { useNetworkManager } from '../util/Network';

const validatePhoneNumber = (input) => {
  const digitInput = input.replace(/\D/g, '');
  if (digitInput.length === 10) {
    return digitInput;
  }
};

function LoginScreen() {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const { setAuth } = useContext(authContext);
  const { makePostRequest } = useNetworkManager();

  const handleTyping = (e) => {
    setError('');
    setPhoneNumber(e.currentTarget.value);
  };

  const handleSubmit = async () => {
    const sanitizedPhoneNumber = validatePhoneNumber(phoneNumber);
    if (sanitizedPhoneNumber) {
      setError('');
      try {
        const { response, error } = await makePostRequest('login', {
          phone: sanitizedPhoneNumber,
        });

        if (response) {
          setAuth(response);
          navigate('/');
        } else {
          setError('Phone number not registered');
        }
      } catch (error) {
        console.error('Error during login:', error);
      }
    } else {
      setError('Invalid phone number');
    }
  };

  return (
    <div>
      <div className='flex flex-col justify-center items-center m-5'>
        <input
          type='text'
          placeholder='Enter your phone number'
          value={phoneNumber}
          onChange={handleTyping}
          className='border-2 border-gray-300 p-2  w-full'
        />
        {error && <div className='text-red-500'>{error}</div>}

        <button
          onClick={handleSubmit}
          className='mt-2 bg-blue-500 text-white p-2 rounded-md'
        >
          Login
        </button>
      </div>
    </div>
  );
}

export { LoginScreen };
