// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react';
import { Navigate } from 'react-router-dom';
import { authContext } from '../contexts/AuthContext';
import { showBottomNav } from './BottomNav';

const AuthedRoute = ({ redirectPath = '/login', children }) => {
  const { isAuthed } = React.useContext(authContext);
  if (!isAuthed()) {
    return <Navigate to={redirectPath} replace />;
  }

  return <div>{children}</div>;
};

export default AuthedRoute;
