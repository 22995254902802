import './wdyr'; // <--- first import

import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import { BottomNav } from './components/BottomNav';
import { Header } from './components/Header';
import { NewInviteScreen } from './screens/NewInviteScreen';
import { ConversationScreen } from './screens/Conversation/ConversationScreen';
import AuthedRoute from './components/AuthedRoute';
import { LoginScreen } from './screens/LoginScreen';
import AuthContextProvider from './contexts/AuthContext';
import InviteProvider from './contexts/InvitesContext';
import ContactsScreen from './screens/ContactsScreen';

const pathsWithBottomNav = ['/', '/new', '/stripes'];
export const showFooter = (path) => {
  return path !== '/login' && pathsWithBottomNav.indexOf(path) !== -1;
};

window.hasCheckedPush = false;

function App() {
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);

  return (
    <AuthContextProvider>
      <InviteProvider>
        <BrowserRouter>
          <Header />

          <Routes>
            <Route
              path='/'
              element={
                <AuthedRoute>
                  <HomeScreen />
                </AuthedRoute>
              }
            />
            <Route
              path='new'
              element={
                <AuthedRoute>
                  <NewInviteScreen />
                </AuthedRoute>
              }
            />
            <Route
              path='i/:inviteId'
              element={
                <AuthedRoute>
                  <ConversationScreen />
                </AuthedRoute>
              }
            />
            <Route path='login' element={<LoginScreen />} />
            <Route path='contacts' element={<ContactsScreen />} />
            <Route path='*' element={<h1>Route does not exist</h1>} />
          </Routes>
          <BottomNav />
        </BrowserRouter>
      </InviteProvider>
    </AuthContextProvider>
  );
}

export default App;
