import * as React from 'react';

export const authContext = React.createContext();
const { Provider } = authContext;

const AuthProvider = ({ children }) => {
  const { isAuthed, authToken, setAuth, clearAuth, getAuth, user } = useAuth();
  return (
    <Provider
      value={{ isAuthed, authToken, setAuth, clearAuth, getAuth, user }}
    >
      {children}
    </Provider>
  );
};

export const DEFAULT_USER_AUTH = { token: null, user_id: null };

const getInitialState = () => {
  return {
    token: localStorage.getItem('token') || '',
    user: JSON.parse(localStorage.getItem('user') || '{}'),
  };
};

export const useAuth = () => {
  const [authData, setAuthData] = React.useState(getInitialState());
  const setAuth = ({ token, user }) => {
    setAuthData({ token, user });

    window.localStorage.setItem('token', token);
    window.localStorage.setItem('user', JSON.stringify(user));
  };

  const clearAuth = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('user');
    setAuthData(getInitialState());
  };
  const isAuthed = () => {
    return authData.token;
  };

  const getAuth = () => {
    return authData;
  };

  const authToken = authData.token;
  const user = authData.user;

  return {
    user,
    authToken,
    isAuthed,
    getAuth,
    setAuth,
    clearAuth,
  };
};

export default AuthProvider;
