import React, { useEffect, useState } from 'react';
import { useNetworkManager } from '../util/Network';
import { useAuth } from '../contexts/AuthContext';
import { PlusCircleIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import { FOOTER_HEIGHT } from '../util/Constants';

const STRIPES_TAB = 'Stripes';
const CONTACTS_TAB = 'Contacts';

export default function ContactsScreen({}) {
  const { makeGetRequest } = useNetworkManager();
  const [contacts, setContacts] = useState();
  const [stripes, setStripes] = useState();
  const { user } = useAuth();

  useEffect(() => {
    const fetchContacts = async () => {
      const { response, error } = await makeGetRequest(`contacts`);

      if (response) {
        setContacts(response);
      }
    };
    if (!contacts) {
      fetchContacts();
    }
  }, [contacts, user.user_id]);

  useEffect(() => {
    const fetchStripes = async () => {
      const { response, error } = await makeGetRequest(`stripes`);
      if (response) {
        setStripes(response);
        if (response.length > 0) {
          setSelectedTab(STRIPES_TAB);
        } else {
          setSelectedTab(CONTACTS_TAB);
        }
      }
    };
    if (!stripes) {
      fetchStripes();
    }
  }, [stripes, user.user_id]);

  const [selectedTab, setSelectedTab] = useState('');

  return (
    <div style={{ marginBottom: FOOTER_HEIGHT }} className='mx-3'>
      <div className='m-2 mt-4 pl-2'>
        <ul className='flex flex-wrap text-sm font-medium text-left gap-4 '>
          <li className='me-2'>
            <button
              onClick={() => {
                setSelectedTab(STRIPES_TAB);
              }}
              className={`inline-flex  group px-2 ${
                selectedTab === STRIPES_TAB
                  ? ' text-black border-b-2 border-black '
                  : 'text-gray-500 hover:text-black'
              }`}
            >
              {STRIPES_TAB}
            </button>
          </li>

          <li>
            <button
              onClick={() => {
                setSelectedTab(CONTACTS_TAB);
              }}
              className={`inline-flex group px-2 ${
                selectedTab === CONTACTS_TAB
                  ? 'text-black  border-b-2 border-black '
                  : 'text-gray-500  hover:text-black'
              }`}
              aria-current='page'
            >
              {CONTACTS_TAB}
            </button>
          </li>
        </ul>
      </div>
      <div>
        {selectedTab === STRIPES_TAB ? (
          <StripesTab stripes={stripes} />
        ) : (
          <ContactsTab contacts={contacts} />
        )}
      </div>
    </div>
  );
}

const StripesTab = ({ stripes }) => {
  return (
    <div>
      {stripes ? (
        <>
          {stripes.map((stripe) => {
            return (
              <div
                key={stripe.stripe_id}
                className='my-3 p-4 bg-white rounded-lg'
              >
                <div className='text-sm font-semibold'>{stripe.name}</div>
                <PeopleRowInStripesTab stripe={stripe} />
              </div>
            );
          })}
          {/* Make a button that floats in a fixed positino on the bottom right of the screen */}
          <button className='fixed bottom-[88px] right-10 p-3 rounded-full'>
            <PlusCircleIcon className='text-zebraTheme bg-white border-none outline-none rounded-full h-16 w-16 shadow-md stroke-1' />
          </button>
        </>
      ) : (
        <div>Empty State</div>
      )}
    </div>
  );
};

const PeopleRowInStripesTab = ({ stripe }) => {
  const numPeopleInStripe = stripe.contacts.length;

  if (numPeopleInStripe === 0) {
    return <div>Click to add people to this stripe</div>;
  }

  let peopleString = '';

  if (numPeopleInStripe === 1) {
    peopleString = stripe.contacts[0].name;
  } else if (numPeopleInStripe === 2) {
    peopleString = stripe.contacts
      .map((person) => {
        return getFirstNameAndLastInitial({ name: person.name });
      })
      .join(' and ');
  } else {
    const namesToShow = stripe.contacts.slice(0, 2).map((person) => {
      return getFirstNameAndLastInitial({ name: person.name });
    });
    const leftoverPeople = numPeopleInStripe - namesToShow.length;

    peopleString =
      namesToShow.join(', ') +
      (leftoverPeople
        ? `, + ${numPeopleInStripe - namesToShow.length} other${leftoverPeople > 1 ? 's' : ''}`
        : '');
  }

  return (
    <div className='flex flex-row mt-1'>
      <UserGroupIcon className='h-5 w-5 mr-2' />
      {peopleString}
    </div>
  );
};

const ContactsTab = ({ contacts }) => {
  if (!contacts) {
    return <div>Empty State</div>;
  }
  // an object in the shape of { A: [contact1, contact2], B: [contact3, contact4] }
  const contactsToLetters = {};
  contacts.forEach((contact) => {
    const firstLetter = contact.name[0].toUpperCase();
    if (!contactsToLetters[firstLetter]) {
      contactsToLetters[firstLetter] = [contact];
    } else {
      contactsToLetters[firstLetter].push(contact);
    }
  });

  return (
    <div className='bg-white rounded-md h-full p-3'>
      {Object.keys(contactsToLetters).map((letter) => {
        return (
          <div key={letter}>
            <div className='font-semibold border-b-gray-300 text-gray-400 text-md my-4'>
              {letter}
            </div>
            {contactsToLetters[letter].map((contact, index) => {
              const isLastItemInList =
                index === contactsToLetters[letter].length - 1;
              return (
                <div
                  key={contact.contact_id}
                  className={`flex flex-row items-center mt-1 py-3 text-md font-medium border-t-2 ${isLastItemInList && 'border-b-2'} ${index === 0 && 'border-t-2'} `}
                >
                  {contact.name}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

const getFirstNameAndLastInitial = ({ name }) => {
  const nameParts = name.split(' ');
  const firstName = nameParts[0];
  const lastNameInitial = nameParts[1] ? ` ${nameParts[1][0]}` : '';
  return `${firstName}${lastNameInitial}`;
};
