import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../contexts/AuthContext';
import {
  ActivityInput,
  DateInput,
  LocationInput,
  ParticipantsInput,
  TimeInput,
} from './InviteFormComponents';
import { useNetworkManager } from '../util/Network';
import dayjs from 'dayjs';

const ERRORS = {
  NO_PARTICIPANTS: 'Please select at least one friend to invite',
  NO_TITLE: 'Please give your activity a name',
  START_TIME_INVALID: 'Enter a valid start time or leave blank',
  END_TIME_INVALID: 'Enter a valid end time or leave blank',
  END_BEFORE_START: 'End time must be after start time',
};

const getErrorsInSubmission = ({
  participants,
  activity,
  startTime,
  endTime,
}) => {
  let errors = [];
  if (participants.length < 1) {
    errors.push(ERRORS.NO_PARTICIPANTS);
  }
  if (activity.trim().length < 1) {
    errors.push(ERRORS.NO_TITLE);
  }

  if (startTime === 'invalid') {
    errors.push(ERRORS.START_TIME_INVALID);
  }

  if (endTime === 'invalid') {
    errors.push(ERRORS.END_TIME_INVALID);
  }

  const startTimeIsDate = dayjs(startTime).isValid();
  const endTimeIsDate = dayjs(endTime).isValid();

  if (startTimeIsDate && endTimeIsDate && dayjs(endTime).isBefore(startTime)) {
    errors.push(ERRORS.END_BEFORE_START);
  }

  return errors;
};
const EditInviteForm = ({ invite, onComplete }) => {
  const [activity, setActivity] = useState(invite?.activity || '');
  const [location, setLocation] = useState(invite?.location || '');
  const [date, setDate] = useState({
    startDate: invite?.start_date || null,
    endDate: invite?.start_date || null,
  });

  // the time inputs are a bit tricky because we don't control their partial state
  // so we'll do null to mean cleared, an ISO string to mean a valid time, and "invalid" to mean partially filled
  const [startTime, setStartTime] = useState(invite?.start_time || null);
  const [endTime, setEndTime] = useState(invite?.end_time || null);
  const [participants, setParticipants] = useState([]);
  const [errors, setErrors] = useState([]);

  const { user } = useContext(authContext);
  const { makeGetRequest } = useNetworkManager();
  const [contacts, setContacts] = useState(undefined); // todo: make this and zebras global stores
  const [stripes, setStripes] = useState(undefined); // todo: make this and zebras global stores
  const [zebras, setZebras] = useState(undefined);
  const [disableInputs, setDisableInputs] = useState(false);

  useEffect(() => {
    const fetchZebras = async () => {
      const { response, error } = await makeGetRequest(`zebras`);
      if (response) {
        setZebras(response);
      }
    };
    if (!zebras) {
      fetchZebras();
    }
  }, [zebras, user.user_id]);

  useEffect(() => {
    const fetchStripes = async () => {
      const { response, error } = await makeGetRequest(`stripes`);
      if (response) {
        setStripes(response);
      }
    };
    if (!stripes) {
      fetchStripes();
    }
  }, [stripes, user.user_id]);

  useEffect(() => {
    const fetchContacts = async () => {
      const { response, error } = await makeGetRequest(`contacts`);

      if (response) {
        setContacts(response);
      }
    };
    if (!contacts) {
      fetchContacts();
    }
  }, [contacts, user.user_id]);

  const onSubmit = async () => {
    const blockingErrors = getErrorsInSubmission({
      participants,
      activity,
      startTime,
      endTime,
    });
    if (invite && blockingErrors.includes(ERRORS.NO_PARTICIPANTS)) {
      // if we're editing an invite, we can ignore the no participants error
      blockingErrors.splice(blockingErrors.indexOf(ERRORS.NO_PARTICIPANTS), 1);
    }
    if (blockingErrors.length > 0) {
      setErrors(blockingErrors);
      return;
    }
    const requestBody = {
      invite_id: invite?.invite_id,
      author_id: user.user_id,
      activity: activity,
      location: location || null,
      start_date: date.startDate || null,
      start_time: startTime || null,
      end_time: endTime || null,
      participants,
    };
    onComplete(requestBody);
  };

  const buttonText = invite ? 'Update Invite' : 'Send Invite';

  return zebras && stripes && contacts ? (
    <div className='flex flex-col w-[85vw] text-gray-900'>
      {/* FIXME: editing participants is a tad triggy but let's come back to this soon */}
      {!invite && (
        <div className='mb-2'>
          <ParticipantsInput
            stripes={stripes}
            contacts={contacts}
            participants={participants}
            onChange={(participants) => {
              setParticipants(participants);
              errors.includes(ERRORS.NO_PARTICIPANTS) &&
                setErrors(
                  errors.filter((error) => error !== ERRORS.NO_PARTICIPANTS)
                );
            }}
          />
          <p
            className={`text-red-500 text-xs ${
              errors.includes(ERRORS.NO_PARTICIPANTS) ? 'block' : 'invisible'
            }`}
          >
            {ERRORS.NO_PARTICIPANTS}
          </p>
        </div>
      )}

      <div className='mb-2'>
        <ActivityInput
          id='activity'
          label='Activity'
          value={activity}
          onChange={(e) => {
            setActivity(e.target.value);
            errors.includes(ERRORS.NO_TITLE) &&
              setErrors(errors.filter((error) => error !== ERRORS.NO_TITLE));
          }}
        />

        <p
          className={`text-red-500 text-xs ${
            errors.includes(ERRORS.NO_TITLE) ? 'block' : 'invisible'
          }`}
        >
          {ERRORS.NO_TITLE}
        </p>
      </div>

      <div className='mb-6'>
        <LocationInput
          value={location}
          onChange={(e) => {
            setLocation(e.target.value);
          }}
        />
      </div>
      <div className='mb-6'>
        <DateInput
          value={date}
          onChange={(e) => {
            setDate(e);
          }}
        />
      </div>
      <div className='flex justify-between sm:justify-start gap-x-2 w-full mb-2'>
        <div>
          <TimeInput
            defaultValue={startTime || null}
            value={startTime || null}
            label='Start Time'
            onChange={(newtime) => {
              setStartTime(newtime);
              setErrors(
                errors.filter(
                  (error) =>
                    error !== ERRORS.START_TIME_INVALID &&
                    error !== ERRORS.END_BEFORE_START
                )
              );
            }}
          />
          <p
            className={`text-red-500 text-xs w-[80%] ${
              errors.includes(ERRORS.START_TIME_INVALID) ? 'block' : 'invisible'
            }`}
          >
            {ERRORS.START_TIME_INVALID}
          </p>
        </div>
        <div>
          <TimeInput
            defaultValue={endTime ? dayjs(endTime) : null}
            value={endTime || null}
            label='End Time'
            onChange={(newtime) => {
              setEndTime(newtime);
              setErrors(
                errors.filter(
                  (error) =>
                    error !== ERRORS.END_TIME_INVALID &&
                    error !== ERRORS.END_BEFORE_START
                )
              );
            }}
          />
          <p
            className={`text-red-500 text-xs  w-[80%] ${
              errors.includes(ERRORS.END_TIME_INVALID) ||
              errors.includes(ERRORS.END_BEFORE_START)
                ? 'block'
                : 'invisible'
            }`}
          >
            {ERRORS.END_TIME_INVALID || ERRORS.END_BEFORE_START}
          </p>
        </div>
      </div>
      <button
        onClick={onSubmit}
        className='bg-sky-700 font-bold py-2 rounded-xl text-white'
      >
        {' '}
        {buttonText}{' '}
      </button>
    </div>
  ) : (
    <></>
  );
};

export { EditInviteForm };
