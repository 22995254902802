// import { useAuth } from "../contexts/AuthContext";
import { useContext } from 'react';
import { authContext } from '../contexts/AuthContext';
import { BASE_URL, PROTOCOL } from './Constants';

const logErrorToServer = (message) => {
  console.log(message); // TODO
};

export const useNetworkManager = () => {
  const { authToken } = useContext(authContext);

  const makeGetRequest = async (url) => makeRequest(url, 'GET');

  const makePostRequest = async (url, body = {}) =>
    makeRequest(url, 'POST', body);

  const makeRequest = async (url, method, body = {}) => {
    const fullUrl = `${PROTOCOL}${BASE_URL}/${url}`;
    let response;
    let error;

    try {
      const fetchResponse = await fetch(fullUrl, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
        body: method === 'GET' ? undefined : JSON.stringify(body),
      });

      if (!fetchResponse.ok) {
        // Handle non-successful status codes
        error = {
          name: 'FetchError',
          message: `HTTP error: ${fetchResponse.status} - ${fetchResponse.statusText}`,
          code: fetchResponse.status,
        };
      } else {
        // Parse the response based on content type
        const jsonResponse = await fetchResponse.json();

        // Check if the response has an error property
        if (jsonResponse.error) {
          error = {
            name: 'FetchError',
            message: jsonResponse.error.message,
            code: jsonResponse.error.code,
          };
        } else {
          response = jsonResponse;
        }
      }
    } catch (e) {
      // Handle network errors
      error = {
        name: 'NetworkError',
        message: 'Failed to fetch data from the server',
        code: -1, // Custom code for network errors
      };
    }

    if (error) {
      console.error(error);
      logErrorToServer(`Error making request to ${url}. Error is: ${error}`);
      console.error(error);
    }

    return { response, error };
  };

  return { makeGetRequest, makePostRequest };
};

export const stringify = (obj) => {
  return JSON.stringify(obj, (key, value) => (value === '' ? null : value));
};
