import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  ExclamationTriangleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';

export const BACKDROP_ZINDEX = 999999;
export function BasicModal({ title, open, setOpen, children }) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-99 flex justify-center items-center'
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div
            style={{
              zIndex: BACKDROP_ZINDEX,
            }}
            className='isolate fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity transform'
          />
        </Transition.Child>
        <div
          style={{
            zIndex: BACKDROP_ZINDEX + 1,
          }}
          className='fixed inset-0 z-10 w-screen overflow-y-auto'
        >
          <div className='flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all overflow-y-scroll h-[95vh] sm:h-[auto]  sm:w-[auto] '>
                <div className='sm:flex sm:items-start'>
                  <div className='mx-auto flex justify-center'>
                    {/* An icon could go here */}
                  </div>
                  <div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'>
                    <Dialog.Title
                      as='h3'
                      className='text-base font-semibold leading-6 text-gray-900'
                    >
                      {title}
                      {/* an X buton on the right */}
                      <button
                        type='button'
                        className='absolute top-2 right-2 p-2 bg-white rounded-full text-gray-700'
                        onClick={() => setOpen(false)}
                      >
                        <XMarkIcon className='h-9 w-9 text-gray-600' />
                      </button>
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-sm text-gray-500'>{children}</p>
                    </div>
                  </div>
                </div>
                <div className='mt-5 sm:mt-4 sm:flex sm:flex-col-reverse'></div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
