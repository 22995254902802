import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function IconDropDownMenu({
  icon,
  options,
  anchor,
  direction = 'left',
}) {
  return (
    <Menu as='div' className='relative inline-block text-left'>
      <Menu.Button as={'div'} className='flex items-center rounded-full'>
        <span className='sr-only'>Open options</span>
        {icon}
      </Menu.Button>

      <DropDownMenuBody
        options={options}
        anchor={anchor}
        direction={direction}
      />
    </Menu>
  );
}

export function DropDownMenu({ title, options, anchor }) {
  return (
    <Menu
      as='div'
      className='relative w-full flex text-left bg-white rounded-md min-h-[85px] '
    >
      <Menu.Button
        as={'button'}
        className='flex w-full justify-center items-center outline:none p-2.5 py-4 text-gray-600 active:text-gray-900 '
      >
        <span className='sr-only'>Open options</span>
        {title}
      </Menu.Button>

      <DropDownMenuBody options={options} anchor={anchor} />
    </Menu>
  );
}
function DropDownMenuBody({ options, anchor = 'top', direction = 'left' }) {
  console.log('anchor', anchor);
  return (
    <Transition
      as={Fragment}
      enter='transition ease-out duration-100'
      enterFrom='transform opacity-0 scale-95'
      enterTo='transform opacity-100 scale-100'
      leave='transition ease-in duration-75'
      leaveFrom='transform opacity-100 scale-100'
      leaveTo='transform opacity-0 scale-95'
    >
      <Menu.Items
        className={`absolute z-10 mt-2 min-w-fit origin-top-right rounded-md bg-white shadow-lg focus:ring-0 outline-none ${
          anchor === 'bottom'
            ? 'bottom-0 left-0'
            : direction === 'right'
              ? 'top-0 left-0'
              : 'top-0 right-0'
        }`}
      >
        <div className='py-1'>
          {options.map((option) => {
            return (
              <Menu.Item key={option.label}>
                {({ active }) => (
                  <a
                    role='button'
                    onClick={option.onClick}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-600',
                      'whitespace-nowrap block w-full px-4 py-2 text-left text-sm rounded-xl'
                    )}
                  >
                    {option.label}
                  </a>
                )}
              </Menu.Item>
            );
          })}
        </div>
      </Menu.Items>
    </Transition>
  );
}
