import React, { useState, useContext } from 'react';
import { authContext } from '../../contexts/AuthContext';
import {
  ActivityTitleRow,
  DateTimeRow,
  LocationRow,
  RespondToInviteRow,
} from '../HomeScreen';
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PencilIcon,
  PencilSquareIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { Transition } from '@headlessui/react';

const ConversationHeader = ({
  invite,
  setEditModalOpen,
  setConfirmDeleteAlertOpen,
  setConfirmDeclineAlertOpen,
}) => {
  const { user } = useContext(authContext);
  const isOwner = user.user_id === invite.author_id;

  const [expanded, setExpanded] = useState(false);

  if (!invite) {
    return null;
  }

  return (
    <section
      role='button'
      onClick={() => {
        setExpanded((prev) => {
          return !prev;
        });
      }}
      className={
        'bg-zinc-100 sticky top-[67px] text-sm p-5 z-50 ' +
        (invite.cancelled_at_timestamp && 'text-gray-400')
      }
    >
      <div className='bg-white p-3 rounded-xl'>
        <div className='flex width-full justify-between items-center '>
          <ActivityTitleRow invite={invite} />
          <div className='flex items-start w-full flex-1'>
            {isOwner && (
              <button
                onClick={() => {
                  setEditModalOpen(true);
                }}
                className='ml-3 mr-3'
              >
                <PencilIcon className='h-4 w-4' />
              </button>
            )}
          </div>
          <div />
          {expanded ? (
            <ChevronUpIcon className='h-5 w-5 mx-2' />
          ) : (
            <ChevronDownIcon role='presentation' className='h-5 w-5 mx-2' />
          )}
        </div>
        <DateTimeRow invite={invite} />
        <LocationRow invite={invite} />

        <Transition
          // enter="transition duration-200 ease-in"
          // enterFrom="transform scale-95 opacity-0"
          // enterTo="transform scale-95 opacity-100"
          // leave="transition duration-100 ease-out"
          // leaveFrom="transform scale-100 opacity-100"
          // leaveTo="transform scale-95 opacity-0"
          show={expanded}
        >
          <div
            className={`mt-2 pt-2 ${expanded ? 'block' : 'hidden'}
      `}
          >
            {!isOwner && (
              <RespondToInviteRow
                invite={invite}
                onDeclineOverride={() => {
                  setConfirmDeclineAlertOpen(true);
                }}
              />
            )}
          </div>
          <div className='flex flex-col items-start text-xs'>
            <div className='mt-2 flex items-center'>
              {isOwner && (
                <>
                  <CheckCircleIcon className='h-5 w-5 bg-green-50 rounded-full mr-1.5 text-green-700' />{' '}
                  Me (Organizer)
                </>
              )}
            </div>
            {invite.participants.map((participant) => {
              if (participant.user_id === invite.author_id) {
                return null;
              }

              return (
                <div key={participant.user_id} className='flex items-center'>
                  {participant.status === 'confirmed' ? (
                    <CheckCircleIcon className='h-5 w-5 bg-green-50 rounded-full mr-1.5 text-green-700' />
                  ) : participant.status === 'declined' ? (
                    <XCircleIcon className='h-5 w-5 bg-red-50 rounded-full mr-1.5 text-red-700' />
                  ) : (
                    <QuestionMarkCircleIcon className='h-5 w-5 bg-yellow-50 p-.5 rounded-full mr-1 text-yellow-700' />
                  )}
                  {participant.name}{' '}
                  {participant.user_id === invite.author_id && '(Organizer)'}
                </div>
              );
            })}
          </div>
        </Transition>
      </div>
    </section>
  );
};

export { ConversationHeader };
